﻿body {
    padding-top: 70px;
}

* {
    border-radius: 0px !important;
}

.login-container {
    margin: auto;
    text-align: center;
}

.js-view-trackers span.glyphicon {
    color: #2a9fd6;
}

.js-view-peers span.glyphicon {
    color: saddlebrown;
}

.js-view-files span.glyphicon {
    color: orange;
}

.js-start-torrent span.glyphicon {
    color: hsl(80,75%,30%);
}

.js-stop-torrent span.glyphicon {
    color: hsl(0,75%,30%);
}

.js-pause-torrent span.glyphicon {
    color: hsl(54,100%,62%);
}

.js-check-torrent span.glyphicon {
    color: hsl(199,68%,50%);
}

.js-delete-torrent span.glyphicon {
    color: gainsboro;
}

.fa-google {
    color: #1649f2;
}

.fa-facebook {
    color: #42639b;
}

.fa-twitter {
    color: #61b2eb;
}

.fa-windows {
    color: #61b3ed;
}

/*.noty_buttons .btn {
    color: #0b90c4;
}*/

#console {
    display: none;
    border-bottom: 1px solid;
    padding-left: 1em;
    padding-right: 1em;
    height: 5em;
    overflow-y: scroll;
    font-family: monospace;

    p {
        margin: 0;
    }
}

#main tr {
    &.stopped {
        .js-stop-torrent, .js-pause-torrent {
            display: none !important;
        }

        background-color: hsl(0,0%,16%);
    }

    &.started {
        .js-start-torrent {
            display: none !important;
        }
    }

    &.hashing {
        .js-stop-torrent, .js-pause-torrent, .js-delete-torrent {
            display: none !important;
        }
    }

    .nowrap a {
        text-decoration: none;
    }
}
